<template>
  <div class="">
    <div class="sm:flex sm:flex-col">
      <div class="sm:py-3">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ action }} {{ newButton.label }}
        </h3>
      </div>
      <div class="sm:space-y-4">
        <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-end sm:border-t sm:border-gray-200 sm:pt-5"
        >
          <label for="first-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Full Name
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              v-model="$v.form.fullname.$model"
              class="max-w-lg block px-2 py-1 w-full border focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
              :class="{ 'border-red-600': $v.form.fullname.$error }"
            />
          </div>
        </div>

        <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-end sm:border-t sm:border-gray-200 sm:pt-5"
        >
          <label for="password" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Password
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="password"
              v-model="$v.form.password.$model"
              class="max-w-lg block px-2 py-1 w-full border focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
              :class="{ 'border-red-600': $v.form.password.$error }"
            />
          </div>
        </div>

        <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-end sm:border-t sm:border-gray-200 sm:pt-5"
        >
          <label
            for="confirm-password"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Confirm Password
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="password"
              v-model="$v.form.confirm_password.$model"
              class="max-w-lg block px-2 py-1 w-full border focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
              :class="{ 'border-red-600': $v.form.confirm_password.$error }"
            />
          </div>
        </div>

        <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-end sm:border-t sm:border-gray-200 sm:pt-5"
        >
          <label for="first-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Email
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="email"
              v-model="$v.form.email.$model"
              class="max-w-lg block px-2 py-1 w-full border focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded"
              :class="{ 'border-red-600': $v.form.email.$error }"
            />
          </div>
        </div>

        <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
        >
          <label for="country" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Group
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <select
              v-model="$v.form.role_id.$model"
              class="max-w-lg block px-2 py-1 w-full border sm:max-w-xs sm:text-sm border-gray-300 rounded"
              :class="{ 'border-red-600': $v.form.role_id.$error }"
            >
              <option value="">Select Group</option>
              <option v-for="(role, index) in roles" :key="`role-${index}`" :value="role.id">
                {{ role.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="sm:mt-8 sm:flex sm:flex-row-reverse">
      <button
        type="button"
        class="w-full inline-flex justify-center rounded border border-transparent px-3 py-1 bg-indigo-600 font-medium text-white hover:bg-indigo-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-xs"
        @click.prevent="update"
      >
        Save
      </button>
      <button
        type="button"
        class="mt-3 w-full inline-flex justify-center rounded border border-gray-300 px-3 py-1 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-xs"
        @click.prevent="setModal({ show: false })"
      >
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { required, requiredIf, email, sameAs } from 'vuelidate/lib/validators'

import http from '@/utils/http'

export default {
  name: 'User',
  data() {
    return {
      form: {
        fullname: '',
        email: '',
        password: '',
        confirm_password: '',
        role_id: '',
      },
      roles: [],
    }
  },
  validations: {
    form: {
      fullname: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required: requiredIf(function () {
          return !this.form.id
        }),
      },
      confirm_password: {
        sameAsPassword: sameAs('password'),
      },
      role_id: {
        required,
      },
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.form = { ...this.form, ...this.modal.data, password: '' }

      http
        .get('/roles')
        .then(response => {
          this.roles = _.map(response.data, role => ({ id: role.id, name: role.role_name }))
        })
        .catch(err => {
          console.log('Error ::: ', err)
        })
    },
    update() {
      this.$v.$touch()

      if (!this.$v.$error) {
        const action = this.form.id
          ? { method: 'put', url: `/users/${this.form.id}` }
          : { method: 'post', url: `/users` }
        http[action.method](action.url, { ...this.form })
          .then(() => {
            this.$bus.$emit(this.modal.notifier)
            this.setModal({ show: false })
          })
          .catch(err => {
            console.log(err)
            this.setModal({ show: false })
          })
      }
    },
  },
  computed: {
    action() {
      return this.form.id ? 'Update' : 'Create'
    },
  },
}
</script>

<style></style>
